module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f1238b9240748d49f470b7358f2fa5d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-starberry-lomondgroup/gatsby-browser.js'),
      options: {"plugins":[],"news_insight":"about-dj-alexander/insights","branch_finder":"branch-finder","area_cover":"about-dj-alexander/areas-we-cover","teams":"about-dj-alexander/meet-the-team","guides_resources":"guides-and-resources"},
    }]
